














































import { Style, WatermarkType } from '@/includes/types/Post.types'
import PostStylesSetup from '@/components/Post/PostStylesSetup.vue'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification } from '@/includes/services/NotificationService'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import validateActions from '@/components/Post/logic/ValidateActions'
import { defaultStyleModel } from '@/includes/logic/Style/constant'
import { Board } from '@/includes/types/Board.types'
import { setPostBoardConfig } from '@/includes/logic/Bot/BotConfig'
import { removeStyle } from '@/includes/logic/Bot/Styles'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Component from 'vue-class-component'
import { cloneDeep, isEmpty } from 'lodash'
import { Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    PageTitle,
    PostStylesSetup,
  },
  data() {
    return {
      EntityTypes
    }
  }
})
export default class StylePage extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  isObjectSet = false

  styleName = ''

  styleObject: Style = { ...defaultStyleModel, disable_notify: true }

  styles: Board['config']['styles'] = {}

  isLoading = false

  get currentBoard(): Board | null {
    return this.$store.state.boardsState.activeBoard
  }

  get disabled() {
    return this.styleObject.watermark_type !== WatermarkType.None && isEmpty(this.styleObject.watermark_data) || !this.styleName
  }

  get styleKey(): string {
    return this.$route.query.styleId?.toString()
  }

  get actionType(): BaseItemViewAction {
    return this.$route.params.actionType.toString() as BaseItemViewAction
  }

  saveStyle(): void {
    const styleObjectCopy = cloneDeep(this.styleObject)

    if (this.actionType === BaseItemViewAction.Edit || this.actionType === BaseItemViewAction.New || this.actionType === BaseItemViewAction.Copy) {

      const validationOk = validateActions(styleObjectCopy.actions)

      if (validationOk) {
        this.isLoading = true

        if (this.styles) {
          if (this.actionType === BaseItemViewAction.Edit && this.styleKey !== this.styleName) {
            this.styles = removeStyle(this.styles, this.styleKey)
          }

          this.$set(this.styles, this.styleName, styleObjectCopy)

          this.savePostBoardConfig()
        }
      } else {
        errorNotification(this.$t('actions_are_not_valid_error'))
      }
    }
  }

  savePostBoardConfig():void {
    if (this.currentBoard) {
      const { board, config, lang, timezone } = this.currentBoard

      setPostBoardConfig(board, {
        lang,
        timezone,
        config: { ...config, styles: this.styles }
      })
        .then(res => {
          if (res) {
            this.$router.push({ name: 'Styles' })
          }
        })
    }
  }

  initLocalStyles() {
    if (this.currentBoard) {
      const { styles } = this.currentBoard.config

      if (isEmpty(styles)) {
        this.styles = {}
      } else {
        this.styles = cloneDeep(styles)
      }
    }
  }

  mounted(): void {
    this.isLoading = true

    this.initLocalStyles()

    if (this.actionType !== BaseItemViewAction.New) {
      const style = this.styles?.[this.styleKey]

      if (style) {
        this.styleObject = cloneDeep(style)

        if (this.actionType === BaseItemViewAction.Copy) {
          delete this.styleObject.id
        }

        this.styleName = this.styleKey
      } else {
        this.$router.push({ name: "Styles" })
      }
    }

    this.isObjectSet = true
    this.isLoading = false
  }
}
