var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config-page"},[_c('page-title',{attrs:{"has-anchor-icon":false}}),_c('a-spin',{staticClass:"w-full",attrs:{"spinning":_vm.isLoading}},[(_vm.isObjectSet && _vm.currentBoard.board)?_c('a-card',[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'styleName',
            'validation': 'required',
          },
        }}}),_c('post-styles-setup',{attrs:{"board":_vm.currentBoard.board},model:{value:(_vm.styleObject),callback:function ($$v) {_vm.styleObject=$$v},expression:"styleObject"}}),_c('div',{staticClass:"buttons-wrapper mt-5 flex justify-end gap-2"},[_c('a-button',{staticClass:"btn-success",attrs:{"disabled":_vm.disabled},on:{"click":_vm.saveStyle}},[_vm._v(" "+_vm._s(_vm.$t('save_button'))+" ")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":function (){ return _vm.$router.back(); }}},[_vm._v(" "+_vm._s(_vm.$t('cancel_button'))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }